
<div class="container-fluid text-center">    
    <div class="row content" style="max-height: 75px">
        <!-- Side bar -->
        <div class="col-sm-12 text-center">
        <h6>Select your Country</h6>
        <div *ngIf="!countries, then spinner else dropdown"></div>
            
            <ng-template #spinner>
            <!-- <div class="loader">Loading countries...</div> -->
            <div id="loading"></div>
            </ng-template>
            
            <ng-template #dropdown>
                <form [formGroup]="countryForm" (change)="changeCountry($event)">
                    <p>
                    <select formControlName="country">
                        <option [ngValue]="null" disabled>Select Country</option>
                        <option *ngFor="let country of countries" [ngValue]="country.name">{{country.name}}</option>
                    </select>
                    </p>
                </form>
            </ng-template>

        </div>
    </div>


    <div class="row content">
        <div class="col-sm-1 text-center">
        </div>
        <div class="col-sm-10 text-center"> 
            <h6>Fossil Locations Map</h6>
            <hr>
            <app-map [selectedCountryObj]="selectedCountryObj"></app-map>
        </div> <!-- End of Map Display-->
        <div class="col-sm-1 text-center">
        </div>
    </div>

</div> <!-- End of Container -->
