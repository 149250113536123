import { environment } from '../../environments/environment';
import { Component, OnInit, OnChanges, Input, SimpleChanges} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { ICountry, IFossil } from 'server/interfaces';
import { GetFossilService } from '../shared/services/fossil.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, OnChanges {
  @Input() selectedCountryObj: ICountry;
  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v11';
  public fossils: IFossil[];
  public selectedCountry: ICountry;
  public zoomLevel = 5.0;
  public fossilMarkers = [];
  public fossilPopups = [];

  constructor(
    private fossilSvc: GetFossilService
  ) { }

  mapClick(newLatLng: any) {
    // The Lat/Lngs associated with the click event have been passed in
    // as an object that has lat and lng properties

    // Get the map's current center lat/lng
    let centerLatLng = this.map.getCenter();

    // Get the map's current zoom level
    let zoomLevel = this.map.getZoom();

    // If the map click was more than .05 degrees away from the current
    // map center point, then get a new set of fossils
    if (Math.abs(newLatLng.lat - centerLatLng.lat) > 0.05
      || Math.abs(newLatLng.lng - centerLatLng.lng) > 0.05)
    { 
        // Set the new selected country
        this.zoomLevel = zoomLevel;
      
        // Create a new map object
        this.createMap();
        //this.removeFossilMarkers();
        this.loadFossilMarkers(newLatLng.lat, newLatLng.lng);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let sco = JSON.parse(JSON.stringify(changes));
    
    if ('selectedCountryObj' in sco) {
        if (!sco.selectedCountryObj.firstChange) {
            
            // Set the new selected country
            this.selectedCountry.name = (changes.selectedCountryObj.currentValue.name as string);
            this.selectedCountry.lat = (changes.selectedCountryObj.currentValue.lat as number);
            this.selectedCountry.lng = (changes.selectedCountryObj.currentValue.lng as number);
           
            // Move the map to focus on the new selected country (and redraw the fossil markers)
            this.map.flyTo({
                center: [
                this.selectedCountry.lng,
                this.selectedCountry.lat
                ],
                essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });
            //this.removeFossilMarkers();
            this.loadFossilMarkers(this.selectedCountry.lat, this.selectedCountry.lng);
        }
      }

    } // end of ngOnChanges


  ngOnInit() {
    // Set a default country
    this.selectedCountry = <IFossil>{};
    //this.selectedCountry.name = 'United States';
    //this.selectedCountry.lat = 37.8;
    //this.selectedCountry.lng = -96.0;
    //this.selectedCountry.name = "Brasil";
		//this.selectedCountry.lat = -14.2350;
		//this.selectedCountry.lng = -51.9253;
    this.selectedCountry.name = "";
    this.selectedCountry.lat = 50.7252;
    this.selectedCountry.lng = -2.9366;
    //console.log('ngOnInit: selectedCountry = ' + JSON.stringify(this.selectedCountry));
    // Set your MapBox credentials
    (mapboxgl as any).accessToken = environment.mapbox.accessToken;

    // Create map
    this.createMap();
    //this.removeFossilMarkers();
    this.loadFossilMarkers(this.selectedCountry.lat, this.selectedCountry.lng);

  } // end of ngOnInit


  createMap() {

    if (!this.map){
        
        // Create the map object
        this.map = new mapboxgl.Map({
          container: 'map',
          style: this.style,
          zoom: this.zoomLevel,
          minZoom: 4,
          center: [this.selectedCountry.lng, this.selectedCountry.lat]
        });
        
        // Add controls to the map
        this.map.addControl(new (mapboxgl as any).NavigationControl());

        // Add zoom listener
        this.map.on('zoomed', (event: any) => {
          this.mapClick(this.map.getCenter());
        })

        // Add click listener
        this.map.on('click', (event: any) => {
          this.mapClick(event.lngLat.wrap() as any);
        })
    }
  }

  removeFossilMarkers() {
    // Remove popups
    if (this.fossilPopups.length > 0) {
      for (var i = this.fossilPopups.length - 1; i >= 0; i--) {
        this.fossilPopups[i].remove();
      }
      this.fossilPopups = [];
    }

    // Remove markers
    if (this.fossilMarkers.length > 0) {
      for (var i = this.fossilMarkers.length - 1; i >= 0; i--) {
        this.fossilMarkers[i].remove();
      }
      this.fossilMarkers = [];
    }
  }

  loadFossilMarkers(lat: number, lng: number) {  

    // Get Fossils for this new location
    this.fossilSvc.getFossil(lat, lng)
    .subscribe(res => {
      let temp = JSON.parse(JSON.stringify(res));
      let fl = temp.fossilList;
      this.fossils = (fl as IFossil[]);
      //console.log(JSON.stringify(this.fossils));

      // After receiving the response, you can...
      // add markers to the map
      this.createMarkers();
      
    });

  } // end of createMap


  createMarkers() {
    //console.log('this.fossils.length: ' + this.fossils.length);
    // Go through each entry creating a marker
    let limit = this.fossils.length;
    if (limit > 5000) { limit = 5000; }
    for(let i = 0; i < limit; i++) {
      //console.log('this.createMarker( ' + this.fossils[i].lng + ', ' + this.fossils[i].lat + ')');  
      if (this.fossils[i].lat && this.fossils[i].lng && this.fossils[i].name){
        this.createMarker(this.fossils[i].lng, this.fossils[i].lat, this.fossils[i].name);
      }
    }
  } // end of createMarkers

  createMarker(lng: number, lat: number, name: string){
    const popup = new mapboxgl.Popup({ offset: 25 }).setHTML('<p>' + name + '</p>');

    new mapboxgl.Marker({
      draggable: false,
      scale: 2.0 / this.zoomLevel
    }).setLngLat([lng, lat])
    .setPopup(popup)
    .addTo(this.map);
    
    //this.fossilMarkers.push(marker);
    //this.fossilPopups.push(popup);
  }

}