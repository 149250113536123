
<!-- ------------------------------------------------------------------ -->
<!-- Navbar -->
<!-- ------------------------------------------------------------------ -->
<!--
<nav class="navbar navbar-inverse">
    <div class="container-fluid">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>                        
            </button>
            <a class="navbar-brand" href="#">FossilChart.org</a>
        </div>
        <div class="collapse navbar-collapse" id="myNavbar">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <a routerLink="../sign-in">
                        <span class="glyphicon glyphicon-log-in">Login/Register</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
-->

<!-- ------------------------------------------------------------------ -->
<!-- Jumbotron -->
<!-- ------------------------------------------------------------------ -->
<div class="container-fluid text-center">    
    <div class="row content">
        
        <!-- Jumbotron -->
        <div class="col-sm-12 text-left"> 
            <div class="jumbotron jumbotron-fluid">
                <div class="container text-center title-text-bg">
                    <h3>&nbsp;</h3>
                    <h1 class="display-4">Welcome to FossilChart</h1>
                    <p class="lead"><b>Where can you find fossils?</b></p>
                    <h3>&nbsp;</h3>
                    <a routerLink="../sign-in">
                        <button type="button" class="btn btn-primary">Get Started!</button>
                        <h4>&nbsp;</h4>
                    </a>

                </div>
            </div>
        </div>
        
    </div>
</div>
    
<!-- ------------------------------------------------------------------ -->  
<!-- Features List -->
<!-- ------------------------------------------------------------------ -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">

        <ul class="timeline">
            
            <li>
            <div>
                <img class="timeline-image" src="../../../assets/1.jpg" alt="">
            </div>
            <div class="timeline-panel">
                <div class="timeline-heading">
                <h4>All the fossils in the world?</h4>
                <h4 class="subheading"></h4>
                </div>
                <div class="timeline-body">
                <p class="text-muted">Well... no, but our dataset comes from the <a href="https://paleobiodb.org/" target="_blank">Paleobiology Database</a> which contains over 1.5 million occurrences from 220,675 collections!</p>
                </div>
            </div>
            </li>

            <li class="timeline-inverted">
            <div>
                <img class="timeline-image" src="../../../assets/2.jpg" alt="">
            </div>
            <div class="timeline-panel">
                <div class="timeline-heading">
                <h4>Easy navigation</h4>
                <h4 class="subheading"></h4>
                </div>
                <div class="timeline-body">
                <p class="text-muted">
                    Quickly zoom to a country using our search bar or just drag and zoom our interactive map.</p>
                </div>
            </div>
            </li>

            <li class="timeline">
                <div>
                    <img class="timeline-image" src="../../../assets/how_do_i_help_cropped.png" alt="">
                </div>
                <div class="timeline-panel">
                    <div class="timeline-heading">
                        <h4>Scientific labels</h4>
                        <h4 class="subheading"></h4>
                    </div>
                    <div class="timeline-body">
                        <p class="text-muted">
                        Click on any marker on the map to view the specimen's scientific name.</p>
                    </div>
                </div>
            </li>
            
            <li class="timeline-inverted">
                <div>
                    <img class="timeline-image" src="../../../assets/information_highway_reduced.jpg" alt="">
                </div>
                <div class="timeline-panel">
                    <div class="timeline-heading">
                        <h4>Free to use</h4>
                        <h4 class="subheading"></h4>
                    </div>
                    <div class="timeline-body">
                        <p class="text-muted">
                        That's right!  You don't pay.  You just sign up and start searching!</p>
                    </div>
                </div>
            </li>

            <li class="timeline">
                <a class="started" routerLink="../sign-in">
                    <div class="timeline-image">
                        <h4>Get<br>Started<br>Now</h4>
                    </div>
                </a>
            </li>
        </ul>
        </div>
    </div>
</div>


<footer class="container-fluid text-center">
    <p>Copyright &copy; veriables.com</p>
</footer>
