import { Component, OnInit, Input } from '@angular/core';
import { ICountry } from 'server/interfaces';
import { AuthService } from "../../shared/services/auth.service";
import { GetCountryService } from '../../shared/services/get-country.service';
import { Observable, pipe } from 'rxjs'
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms'
import { ThrowStmt } from '@angular/compiler';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public countries: ICountry[];
  public selectedCountryName: String = ''
  public selectedCountryObj: ICountry;
  countryForm: FormGroup;

  constructor(
    private authService: AuthService,
    private countriesSvc: GetCountryService,
    private fb: FormBuilder,
    public router: Router
  ) { }

  ngOnInit(): void {
    // Check if the user is logged in, if not navigate to the sign-in page
    if(this.authService.isLoggedIn !== true) {
      this.router.navigate(['home'])
    }

    // Initialize the formGroup
    this.countryForm = this.fb.group({
      country: [null]
    });

    this.countriesSvc.getCountry()
    .subscribe(res => {
      this.countries = JSON.parse(JSON.stringify(res)).countryList;
      this.countryForm.patchValue({
        countryName: JSON.parse(JSON.stringify(res)).countryList
      });
    })
  }

  changeCountry(e) {
    this.selectedCountryName = e.target.options[e.target.options.selectedIndex].text;
    this.selectedCountryObj = this.getCountryFromName(this.selectedCountryName);
  }

  convertToICountry(input: any) {
    let c = <ICountry>{};
    c.name = input.name;
    c.lat = input.lat;
    c.lng = input.lng;
    return c;
  }

  getCountryFromName(countryName: String) {
    let output = <ICountry>{};
    for (let i=0; i < this.countries.length; i++) {
      if (this.countries[i].name == countryName) {
        output.name = this.countries[i].name;
        output.lat = this.countries[i].lat;
        output.lng = this.countries[i].lng;
      }
    }

    return output;
  }

}
