<div [hidden]="!this.map.loaded()">
    <div class="map" id="map" class="match-parent"></div>
</div>
<!-- 
<div [hidden]="this.map.loaded()">
    <div class='mapmsg'>Loading Fossil data</div>
</div>
-->
<div [hidden]="this.map.loaded()">
    <div id="loading"></div> 
</div> 