import { Injectable } from '@angular/core';

// You'll need the Auth service so you can get the Authorization token (jwt)
import { AuthService } from "../../shared/services/auth.service";

// You'll need the base_url from the environment settings
import {environment} from '../../../environments/environment';

// Import HttpClient so you can make web service requests
import {HttpClient, HttpParams} from '@angular/common/http';

// The HttpClient uses Observables for everything, so import them
import { catchError, retry } from 'rxjs/operators';
import {Observable, pipe, throwError, from} from 'rxjs';

// You'll want to pass typed objects in your Http transactions
import {ICountry, IFossil} from '../../../../server/interfaces';

@Injectable({
  providedIn: 'root'
})


export class GetFossilService {

  constructor(
    private http: HttpClient,
    private authService: AuthService) { }

  public getFossil(lat: Number, lng: Number): Observable<IFossil[]> {
    const params = new HttpParams()
      .set('Authorization', this.authService.GetToken());
    let queryStr = '?Authorization=' + this.authService.GetToken();
        queryStr += '&lat=' + lat;
        queryStr += '&lng=' + lng;
    //const url = environment.api.baseUrl + '/api/getFossils' + queryStr;
    //const url = 'http://localhost:9000' + '/api/getFossils' + queryStr;
    const url = 'https://fossils-2d704.uc.r.appspot.com' + '/api/getFossils' + queryStr;
    //console.log('FossilService URL called');
    //console.log(url);

    return this.http.get<IFossil[]>(url);
  }


}
