// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
      apiKey: "AIzaSyAZS2gm8MTC8nn7DOUUvyC5Su8mAMlj_-k",
      authDomain: "fossils-2d704.firebaseapp.com",
      projectId: "fossils-2d704",
      storageBucket: "fossils-2d704.appspot.com",
      messagingSenderId: "83344829945",
      appId: "1:83344829945:web:1baec66b284a19e190a128"
  },
  api: {
    baseUrl: ''
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoidmVyaWFibGVzIiwiYSI6ImNrcHoxb2g3bTBrenUyd240NXBoczNyZm8ifQ.USa56kAtMuXjP4t_zzlJIQ'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
